import react, { useState } from "react";
import FaqList from "../FaqList";
import TestimonialList from "../TestimonialList";
import AdminNav from "./AdminNav";
import Gallery from "../Gallery";
import { Navigate, useSearchParams } from "react-router-dom";
import GalleryList from "../GalleryList";
 
const Admin = () => { 
  const [params] = useSearchParams();
  const [showContent, setShowContent] = useState(params.get("page") || "testimonials");
  const token = localStorage.getItem("token");
 
  let content = <TestimonialList   />;
  switch (showContent) {
    case "testimonials":
      break;
    case "gallery":
      content = <GalleryList  />;
      break;
    case "faq":
      content = <FaqList  />;
      break;
  }
  if (!token) {
    return <Navigate to="/" />
  }
  return (
    <>
      <AdminNav showContent={showContent} setShowContent={setShowContent} />
      {content}
    </>
  );
};

export default Admin;
