import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="main other-pages">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading-content text-center">
                <h5 className="section-title"> About Us </h5>
                <h2 className="section-subheading">
                  {" "}
                  What we can do for you?{" "}
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="page-content text-center">
                <img
                  src="/img/about/Top-quality-Breeder.jpg"
                  className="img-fluid"
                  alt=""
                />
                <p className="text-center mt-3">
                  {" "}
                  <p>
                    Welcome to Foresthaven, we are NSW Registered Breeder and
                    the home of superior Toy and Miniature Schnauzers. We are
                    mad about schnauzers and find fulfilment in producing pups
                    to an outstanding quality both in looks and temperament. We
                    feel privileged and are able to acheive this standard
                    because we have dogs with top bloodlines from around the
                    world including Australia and America. Our Dogs and puppies
                    are family to us and all our time is devoted to spending
                    with them. Foresthaven pups are becoming known for their
                    confident and loving natures making them the perfect famiy
                    pet, we believe a big part of this is all the time and love
                    we give them right from birth.
                  </p>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main bg-pink">
        <div className="container">
          <div className="row mb-4 align-items-center">
            <div className="col-md-6 order-2">
              <div className="block-left">
                <h4> Ankc Registered </h4>
                <p>
                  {" "}
                  We are ANKC Registered Breeders. We offer Schnauzer puppies
                  with pedigree papers registered with the ANKC Kennel Club. You
                  can see three generations of the pup's breeding (its family
                  tree) in its papers. Your pup will be registered in your name.
                  You are welcome to choose the name for your pup and register
                  it with that name.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 order-md-2">
              <div className="block-right">
                <div className="img-cont">
                  <img
                    src="/img/about/ANKC-Registration.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 align-items-center">
            <div className="col-md-6">
              <div className="block-left">
                <div className="img-cont">
                  <img
                    src="/img/about/Top-quality-Breeder.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="block-right">
                <h4> Top Quality Breeders </h4>
                <p>
                  This is a German breed of great antiquity, appearing in
                  paintings of Durer and Rembrandt. In Mechlinburg a statue
                  dating back to the 14th century of a hunter with a schnauzer
                  crouching at his feet stands in the market place. All the
                  schnauzers had their origins in the neighboring kingdoms of
                  Bavaria and Wurtemmburg.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 align-items-center">
            <div className="col-md-6">
              <div className="block-left">
                <h4> raised in comfy property spread in 5 acres</h4>
                <p>
                  This is a German breed of great antiquity, appearing in
                  paintings of Durer and Rembrandt. In Mechlinburg a statue
                  dating back to the 14th century of a hunter with a schnauzer
                  crouching at his feet stands in the market place. All the
                  schnauzers had their origins in the neighboring kingdoms of
                  Bavaria and Wurtemmburg.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="block-right">
                <div className="img-cont">
                  <img
                    src="/img/about/Raised-in-comfy.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
