import { useEffect, useState } from "react";
import { http } from "../utils/httpreq";
import { Navigate, useParams } from "react-router-dom";
import CustomButton from "./Button";
import notify from "../utils/notify";
import Back from "./Common/Back";

const TestimonialEdit = () => {
  const [name, setName] = useState();
  const [thumbnail, setThumbnail] = useState(null);
  const [logging, setLogging] = useState(false);
  const [description, setDescription] = useState();
  const [info, setInfo] = useState({
    customerName: "",
    description: "",
  });
  const token = localStorage.getItem("token");
  const params = useParams();
  useEffect(() => {
    const runAsync = async function () {
      const response = await http(true, null).get("/testimonial/" + params.id);
      const data = response.data;
      if (data) {
        setInfo({
          ...info,
          customerName: data.customerName || "",
          description: data.description || "",
        });
        if (data.thumbnail) {
          setThumbnail(data.thumbnail);
        }
      }
    };
    runAsync();
  }, []);

  const handleChange = function (e) {
    let { name, value, type, files } = e.target;
    if (type == "file") {
      setThumbnail(files[0]);
      return;
    }
    setInfo({ ...info, [name]: value });
  };
  if (!token) {
    return <Navigate to="/" />;
  }
  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();
    const formData = new FormData();
    for (let key in info) {
      formData.append(key, info[key]);
    }
    if (thumbnail && typeof thumbnail == "object") {
      // for (let i = 0; i < files.length; i++) {
      formData.append("thumbnail", thumbnail);
      // }"
    }
    try {
      const postRequiredData = await http(true, true).put(
        "/testimonial/" + params.id,
        formData
      );
      setLogging(false);
      notify.showSuccess("Testimonial edited successfully.")
    } catch (err) {
      setLogging(false);
      notify.showError(err.response)
    }
  }
  return (
    <>
      <div className="admin-form main-md">
        <div className="container">
          <Back page="testimonials" />
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Edit Testimonials </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="exampleName"> Customer Name </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleName"
                      onChange={handleChange}
                      name="customerName"
                      value={info.customerName}
                      aria-describedby="nameHelp"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="customFile">Customer Image </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={handleChange}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="exampleInputPassword1"> Description</label>
                    <textarea
                      className="form-control"
                      id="validationTextarea"
                      placeholder="Add Description"
                      onChange={handleChange}
                      name="description"
                      value={info.description}
                      required
                    ></textarea>
                  </div>
                </div>

                <CustomButton isDisabled={logging} />
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* pictures */}
      <div className="main other-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4>Thumbnail Preview</h4>
              <div className="masorny-grid">
                {thumbnail ? (
                  typeof thumbnail == "object" ? (
                    <figure>
                      <img
                        src={URL.createObjectURL(thumbnail)}
                        className="img-fluid"
                        alt={thumbnail.name}
                      />
                    </figure>
                  ) : (
                    <figure>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/public/${thumbnail}`}
                        className="img-fluid"
                        alt={thumbnail.name}
                      />
                    </figure>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialEdit;
