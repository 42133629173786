
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div className="hero-image-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="hero-content container h-100">
                <div className="d-flex h-100 text-left align-items-center">
                  <div className="w-100 text-white">
                    <h1 className="">
                      {" "}
                      We are here for your pets.
                    </h1>
                    <p className="lead mb-3">
                      Welcome to Foresthaven, we are a NSW Registered Breeder
                      and the home of superior Toy and Miniature Schnauzes.
                    </p>

                    <div className="btn-more">
                      <Link to="/contact" className="btn btn-brand">
                        {" "}
                        Know More <img src="/img/chevron-down.svg" alt="" />
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-none d-md-block d-lg-block d-xl-block">
              <div className="banner-image text-center">
                <img
                  src="/img/banner/banner-image.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
