import React from "react";
import { Link } from "react-router-dom";

const Back = (props) => {
  return (
    <div className="row mb-3">
      <div className="col-md-12">
        <Link to={`/admin/home?page=${props.page}`} className="back-btn">
          Back
        </Link>
      </div>
    </div>
  );
};


export default Back;