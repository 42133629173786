
const CustomButton = (props) => {
  const enabledLable = props.enabledLable || "Submit";
  const disabledLable = props.disabledLable || "Sumbitting...";
  const isDisabled = props.isDisabled;
  const classNames = props.classNames || "btn-fill btn-block";
  const variant = props.variant || "info";

  // <Button className={classNames} type="submit" variant={variant} disabled = {isDisabled}>
  //   {isDisabled ? disabledLable : enabledLable}
  // </Button>
  return (

    <button disabled={isDisabled} type="submit" className="btn btn-brand-warning">
      {isDisabled ? disabledLable : enabledLable}
    </button>
  );
};

export default CustomButton;
