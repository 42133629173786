import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../utils/httpreq";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true)
    const getRequiredData = async () => {
      
      const response = await http(null, null).get("/gallery");
      setGallery(response.data);
      setIsLoading(false)
    };

    getRequiredData();
  }, []);

  return isLoading ? (
    <p>Loading..</p>
  ) : (
    <>
      <section className="main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading-content text-center">
                <h5 className="section-title"> Our Albums </h5>
                <h2 className="section-subheading">
                  {" "}
                  Some of Our Precious Memories{" "}
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            {gallery.map((item) => {
              return (
                <div key={item._id} className="col-md-4">
                  <div className="card brand-card mb-3">
                    <Link to={`/gallery/${item._id}`} className="divLink" />
                    <div className="card-header">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/public/${item.thumbnail}`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="card-body">
                      <h3> {item.title} </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
