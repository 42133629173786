import { useState } from "react";
import { http } from "../utils/httpreq";
import { Navigate } from "react-router-dom";
import CustomButton from "./Button";
import notify from "../utils/notify";

const TestimonialAdd = () => {
  const [file, setFile] = useState();
  const [info, setInfo] = useState({
    customerName: "",
    description: "",
  });

  const [logging, setLogging] = useState(false);
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" />;
  }

  function handleChange(e) {
    let { type, name, value, files } = e.target;
    if (type == "file") {
      setFile(files[0]);
      return;
    }
    setInfo({ ...info, [name]: value });
  }

  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();
    const formData = new FormData();

    for (let key in info) {
      formData.append(key, info[key]);
    }
    if (file) {
      // for (let i = 0; i < files.length; i++) {
      formData.append("thumbnail", file);
      // }
    }
    try {
      const postRequiredData = await http(true, true).post(
        "/testimonial",
        formData
      );
      setLogging(false);
      setInfo({
        description: "",
        customerName: "",
      });
      notify.showSuccess("Testimonial added successfully.")
      setFile(null);
    } catch (err) {
      setLogging(false);
      notify.showError(err.response)
    }
  }
  return (
    <>
      <div className="admin-form main-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Add Testimonials </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="exampleName"> Customer Name </label>
                    <input
                      type="text"
                      name="customerName"
                      className="form-control"
                      id="exampleName"
                      onChange={handleChange}
                      value={info.customerName}
                      aria-describedby="nameHelp"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="customFile">Customer Image </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        name="thumbnail"
                        className="custom-file-input"
                        id="customFile"
                        onChange={handleChange}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="exampleInputPassword1"> Description</label>
                    <textarea
                      name="description"
                      className="form-control"
                      id="validationTextarea"
                      placeholder="Add Description"
                      value={info.description}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                <CustomButton isDisabled={logging} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialAdd;
