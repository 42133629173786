import React from "react";
import { Link, useNavigate } from "react-router-dom";

const AdminNav = ({ setShowContent, showContent }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }
  return (
    <>
      <section className="subnav-wrapper bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ul className="list-unstyled d-flex mb-0 justify-content-between">
                <li className={showContent === "testimonials" ? "active" : ""}>
                  <Link
                    onClick={() => {
                      setShowContent("testimonials");
                    }}
                    to=""
                    className=""
                  >
                    Testimonials
                  </Link>
                </li>
                <li className={showContent === "gallery" ? "active" : ""}  >
                  <Link
                    onClick={() => {
                      setShowContent("gallery");
                    }}
                    to=""
                    className=""
                  >
                    Gallery
                  </Link>
                </li>
                <li className={showContent === "faq" ? "active" : ""} >
                  <Link
                    onClick={() => {
                      setShowContent("faq");
                    }}
                    to=""
                    className=""
                  >
                    FAQ's
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminNav;
