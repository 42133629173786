import { useState } from "react";
import { Navigate } from "react-router-dom";
import { http } from "../utils/httpreq";
import notify from "../utils/notify";
import CustomButton from "./Button";

const FaqAdd = () => {
  //1. getting value from the field input
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [logging, setLogging] = useState(false);
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" />;
  }

  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();

    try {
      await http(true, null).post("/faq", {
        //2. send along to server
        question,
        answer,
      });
      setAnswer("");
      setQuestion("");
      setLogging(false);
      notify.showSuccess("Faq added successfully.");
    } catch (err) {
      setLogging(false);
      notify.showError(err.response);
    }
  }
  return (
    <>
      <div className="admin-form main-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Add FAQ's </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit}>
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="questionField"> Question</label>
                    <input
                      type="text"
                      className="form-control"
                      id="questionField"
                      onChange={(e) => {
                        setQuestion(e.target.value);
                      }}
                      required
                      value={question}
                    />
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="answerField"> Answer </label>
                    <textarea
                      className="form-control"
                      id="answerField"
                      placeholder="Add Answer"
                      onChange={(e) => {
                        setAnswer(e.target.value);
                      }}
                      value={answer}
                      required
                    ></textarea>
                  </div>
                </div>

                <CustomButton isDisabled={logging} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqAdd;
