// import axios from "axios";
import { useState, useEffect } from "react";
import { http } from "../utils/httpreq";
import { Link } from "react-router-dom";
import notify from "../utils/notify";

const TestimonialList = () => {
  const [testimonial, setTestimonial] = useState([]);
  const [isDeleting, setIsDeleting] = useState({
    state: false,
    id: null,
  });

  useEffect(() => {
    const getRequiredData = async () => {
      const testimonialData = await http(null, null)
        .get("/testimonial")
        .then((res) => {
          setTestimonial(res.data);
        })
        .catch((err) => {
        });
    };
    getRequiredData();
  }, []);

  const handleDelete = async (id) => {
    try {
      setIsDeleting({ state: true, id: id });
      await http(true, null).delete("/testimonial/" + id);
      const newTestimonials = testimonial.filter((t) => t._id !== id);
      setTestimonial(newTestimonials);
      setIsDeleting({ state: false, id: null });
      notify.showSuccess("Testimonial deleted successfully.")
    } catch (err) {
      setIsDeleting({ state: false, id: null });
      notify.showSuccess(err.response)
    }
  };

  return (
    <>
      <section className="main testimonial-table">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h4 className="admin-title"> Testimonials List </h4>
            </div>
            <div className="col-md-2 text-right">
              <Link
                to="/admin/testimonial/add"
                className="btn btn-brand-warning"
              >
                {" "}
                <img
                  src="/img/add.png"
                  style={{ width: "12px", marginRight: "15px" }}
                  alt=""
                />
                Add New{" "}
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table admin-table">
                  <thead>
                    <tr>
                      <th scope="col" width="5%">
                        S.No.
                      </th>
                      <th scope="col" width="10%">
                        Customer Name
                      </th>
                      <th scope="col" width="30%">
                        Description
                      </th>
                      <th scope="col" width="10%">
                        CreatedAt
                      </th>
                      <th scope="col" width="10%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testimonial.map((item, index) => {
                      return (
                        <tr key={index} >
                          <td scope="row">{index + 1}</td>
                          <td className="username">{item.customerName}</td>
                          <td className="description">{item.description}</td>
                          <td>{item.createdAt}</td>
                          <td className="">
                            <Link
                              to={`/admin/testimonial/${item._id}`}
                              className="mr-2"
                            >
                              <img src="/img/view.png" alt="" />
                            </Link>
                            <Link
                              to={`/admin/testimonial/${item._id}`}
                              className="mr-2"
                            >
                              <img src="/img/edit.png" alt="" />
                            </Link>
                            {isDeleting &&
                            isDeleting.state &&
                            isDeleting.id === item._id ? (
                              "loading"
                            ) : (
                              <Link
                                to=""
                                onClick={() => handleDelete(item._id)}
                                className="mr-2"
                              >
                                <img src="/img/delete.png" alt="" />
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialList;
