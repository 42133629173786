import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="footer main-sm">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-3 col-12">
              <div className="footer-wrapper">
                <div className="footer-body">
                  <div className="footer-logo">
                    <img src="/img/badge.png" className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="footer-wrapper">
                <div className="footer-header">
                  <h3> Find Us </h3>
                </div>
                <div className="footer-body">
                  <ul className="list-unstyled">
                    <li>
                      <span> Jervis Bay NSW 2540, Australia </span>
                    </li>
                    <li>
                      <span>0498 196 670 </span>
                    </li>
                    <li>
                      <span>foresthavenschnauzers@gmail.com </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="footer-wrapper">
                <div className="footer-header">
                  <h3> Sitemap </h3>
                </div>
                <div className="footer-body">
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/"> Home </Link>
                    </li>
                    <li>
                      <Link to="/about"> About </Link>
                    </li>
                    <li>
                      <Link to="/contact"> Contact </Link>
                    </li>
                    <li>
                      <Link to="/faq"> Faq </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12">
              <div className="footer-wrapper">
                <div className="footer-header">
                  <h3> Contact Us </h3>
                </div>
                <div className="footer-body">
                  <p> Please feel free to contact us for any queries.</p>

                  <a href="/contact" className="btn btn-brand">
                    {" "}
                    Send Message{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
