import { BrowserRouter, Routes, Route } from "react-router-dom";
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
// import { Helmet } from "react-helmet";

// pages
import Home from "./components/Home";
import About from "./components/About";
import Testimonial from "./components/Testimonial";
import Gallery from "./components/Gallery";
import GalleryDetails from "./components/GalleryDetails";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import TestimonialAdd from "./components/TestimonialAdd";
import FaqAdd from "./components/FaqAdd";
import Footer from "./components/Footer";
import Admin from "./components/Admin/Admin";
import GalleryAdd from "./components/GalleryAdd";
import PictureAdd from "./components/PictureAdd";
import TestimonialEdit from "./components/TestimonialEdit";
import FaqEdit from "./components/FaqEdit";
import GalleryEdit from "./components/GalleryEdit";

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/testimonial" element={<Testimonial />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/gallery/:id" element={<GalleryDetails />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route
          exact
          path="/admin/testimonial/list"
          element={<TestimonialList />}
        /> */}
        {/* <Route exact path="/admin/faq/list" element={<FaqList />} /> */}
        <Route
          exact
          path="/admin/testimonial/add"
          element={<TestimonialAdd />}
        />
        <Route
          exact
          path="/admin/testimonial/:id"
          element={<TestimonialEdit />}
        />
        <Route exact path="/admin/faq/add" element={<FaqAdd />} />
        <Route exact path="/admin/faq/:id" element={<FaqEdit />} />
        <Route exact path="/admin/pictures/add/:id" element={<PictureAdd />} />
        <Route exact path="/admin/home" element={<Admin />} />
        <Route exact path="/admin/gallery/add/" element={<GalleryAdd />} />
        <Route exact path="/admin/gallery/:id" element={<GalleryEdit />} />

        <Route />
        <Route exact path="/admin/login" element={<Login />} />

        <Route />
      </Routes>

      <Footer />
      <ToastContainer/>
    </BrowserRouter>
  );
}

export default App;
