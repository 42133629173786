import axios from "axios";

const headers = (secured, file) => {
  let options = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (secured) {
    options["Authorization"] = localStorage.getItem("token");
    options["x-access-token"] = localStorage.getItem("token");
  }
  if (file) {
    options["Content-Type"] = "multipart/form-data";
  }
  return options;
};

export const http = (secured, file) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers(secured, file),
  });
}; 
