// import axios from "axios";
import { useState, useEffect } from "react";
import { http } from "../utils/httpreq";

const Testimonial = () => {
  // const [testimonial, setTestimonial] = useState([]);

  // useEffect(() => {
  //   fetch("http://localhost:3000/testimonials")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw Error("cannot load data");
  //       }

  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTestimonial(data);
  //     })
  //     .catch((err) => {
  //     });
  // }, []);

  const [testimonial, setTestimonial] = useState([]);
 
  useEffect(() => {
    // const getRequiredData = async () => {
    //   const testimonialData = await axios
    //     .get("http://localhost:8000/testimonial")
    //     .then((res) => {
    //       setTestimonial(res.data);
    //     })
    //     .catch((err) => {
    //     });
    // };

    const getRequiredData = async () => {
      const testimonialData = await http(null, null)
        .get("/testimonial")
        .then((res) => {
          setTestimonial(res.data);
        })
        .catch((err) => {
        });
    };

    getRequiredData();
  }, []);


  return (
    <>
      <section className="main testimonial-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <h5 className="section-title"> OUR HAPPPIEST CLIENTS </h5>
              <h2 className="section-subheading">
                {" "}
                Meet the pet lovers who treats pet like family.
              </h2>
              <p>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.Lorem Ipsum has been the industry's standard dummy .
              </p>
            </div>
          </div>

          <div className="row mt-5">
            {testimonial.map((item) => {
              return (
                <div className="col-md-4">
                  <div className="testimonial-wrapper" key={item._id}>
                    <div className="testimonial-header d-flex align-items-center">
                      <div className="img-circle">
                        <img
                          src={
                            "http://localhost:8000/public/uploads/" +
                            item.thumbnail
                          }
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="info-content ml-3">
                        <h4> {item.customerName} </h4>
                        <small> Customer </small>
                      </div>
                    </div>
                    <div className="testimonial-body">
                      <p> {item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
