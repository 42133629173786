import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { http } from "../utils/httpreq";
import Testimonial from "./Testimonial";
import { Link } from "react-router-dom";
import notify from "../utils/notify";

const FaqList = () => {
  let [faq, setFaq] = useState([]);
  const [isDeleting, setIsDeleting] = useState({
    state: false,
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (id) => {
    try {
      setIsDeleting({ state: true, id: id });
      await http(true, null).delete("/faq/" + id);
      const newTestimonials = faq.filter((t) => t._id !== id);
      setFaq(newTestimonials);
      setIsDeleting({ state: false, id: null });
      notify.showSuccess("Faq deleted successfully.");
    } catch (err) {
      setIsDeleting({ state: false, id: null });
      notify.showError(err.response);
    }
  };
  useEffect(() => {
    const getRequiredData = async () => {
      setIsLoading(true);
      try {
        const res = await http(null, null).get("/faq");
        setFaq(res.data);
        setIsLoading(false);
      } catch (err) {
        notify.showError(err.response);
        setIsLoading(false);
      }
    };
    getRequiredData();
  }, []);
  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <>
      {/* <section className="subnav-wrapper bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ul className="list-unstyled d-flex mb-0 justify-content-between">
                <li className="active">
                  <Link to="/admin/testimonial/list" className="">
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link to="/admin/gallery/list" className="">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/admin/faq/list" className="">
                    FAQ's
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <section className="main testimonial-table">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h4 className="admin-title"> Faq's List </h4>
            </div>
            <div className="col-md-2 text-right">
              <Link to="/admin/faq/add" className="btn btn-brand-warning">
                {" "}
                <img
                  src="/img/add.png"
                  style={{ width: "12px", marginRight: "15px" }}
                  alt=""
                />
                Add New{" "}
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table admin-table">
                  <thead>
                    <tr>
                      <th scope="col" width="5%">
                        S.No.
                      </th>
                      <th scope="col" width="35%">
                        Question
                      </th>
                      <th scope="col" width="40%">
                        Answer
                      </th>
                      <th scope="col" width="10%">
                        CreatedAt
                      </th>
                      <th scope="col" width="10%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {faq.map((item, index) => {
                      return (
                        <tr key={index} >
                          <td scope="row">{index + 1}</td>
                          <td className="username">{item.question}</td>
                          <td className="description">{item.answer}</td>
                          <td>{item.createdAt}</td>
                          <td>
                            {" "}
                            <Link
                              to={`/admin/faq/${item._id}`}
                              className="mr-2"
                            >
                              <img src="/img/edit.png" alt="" />
                            </Link>
                            {isDeleting &&
                            isDeleting.state &&
                            isDeleting.id === item._id ? (
                              "loading"
                            ) : (
                              <Link
                                to=""
                                onClick={() => handleDelete(item._id)}
                                className="mr-2"
                              >
                                <img src="/img/delete.png" alt="" />
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqList;
