import { useState } from "react";
import { http } from "../utils/httpreq";
import { Navigate } from "react-router-dom";
import CustomButton from "./Button";
import notify from "../utils/notify";

const TestimonialAdd = () => {
  //   const [title, setTitle] = useState();
  //   const [thumbnail, setThumbnail] = useState();
  const [info, setInfo] = useState({
    title: "",
  });
  const [file, setFile] = useState("");
  const [logging, setLogging] = useState(false)
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" />
  }

  const handleChange = (e) => {
    var { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();
    const formData = new FormData();

    for (let key in info) {
      formData.append(key, info[key]);
    }

    if(file){
        formData.append("thumbnail", file);
    }
    try {
     await http(true, true).post("/gallery", formData);
      setLogging(false)
      setInfo({title: ""})
      setFile(null)
      notify.showSuccess("Gallery added successfully.")
    } catch (err) {
      setLogging(false)
      notify.showError(err.response)
    }
  }
  return (
    <>
      <div className="admin-form main-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Add Gallery </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="exampleName"> Title </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleName"
                      name="title"
                      value={info.title}
                      onChange={handleChange}
                      aria-describedby="nameHelp"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="customFile">thumbnail </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
                <CustomButton isDisabled={logging} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialAdd;
