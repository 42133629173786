import * as React from "react";
import { useState, useEffect } from "react";
import Hero from "./Hero";

import { http } from "../utils/httpreq";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
// import "swiper/css/pagination";

//lightbox
import Lightbox from "yet-another-react-lightbox";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const Home = () => {
  const [index, setIndex] = React.useState(-1);

  const [testimonial, setTestimonial] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [faq, setFaq] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const getRequiredData = async () => {
  //     try {
  //       // const testimonials = await fetch("http://localhost:3000/testimonials");
  //       const allTestimonials = await axios
  //         .get("http://localhost:3000/testimonials")
  //         .then((response) => {
  //           setTestimonial(response.data);
  //         });
  //     } catch (err) {
  //     }
  //   };
  //   getRequiredData();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const getRequiredData = async () => {
      try {
        //get testimonial
        const allTestimonial = await http(null, null).get("/testimonial");
        // get Gallery
        const allGallery = await http(null, null).get("/gallery-pictures");

        setGallery(allGallery.data);
        let data = [];
        let keys = Object.keys(allGallery.data);
        for (let i = 0; i < 2; i++) {
          if (keys[i]) {
            let curGallery = allGallery.data[keys[i]];
            if (curGallery.pictures) {
              for (let i = 0; i < 5; i++) {
                if (curGallery.pictures[i]) {
                  data.push(curGallery.pictures[i])
                }
              }
            }
            // data[i] = allGallery.data[keys[i]];
          }
        }

        setGallery(data);

        setGallery(data);
        const allFaq = await http(null, null)
          .get("/faq?limit=4")
          .then((res) => {
            setFaq(res.data);
          })
          .catch((err) => {});

        setTestimonial(allTestimonial.data);
        // setGallery(allGallery.data);
      } catch (err) {}
    };

    getRequiredData();
  }, []);

  return (
    <>
      <Hero />

      <section className="main about-section">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="section-image-wrapper">
                <div className="hi-img">
                  <img src="img/about-hi.svg" className="img-fluid" alt="" />
                </div>
                <img src="img/about.png" className="img-fluid" alt="" />
                <div className="about-img">
                  <img src="img/about-tail.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="bone-1 mb-5 d-none d-md-block d-lg-block d-xl-block">
                <img src="img/about-bone.png" className="img-fluid" alt="" />
              </div>
              <div className="section-content positioning">
                <div className="bone-2 d-none d-md-block d-lg-block d-xl-block">
                  <img src="img/about-bone1.png" className="img-fluid" alt="" />
                </div>
                <div className="bone-3 d-none d-md-block d-lg-block d-xl-block">
                  <img src="img/about-bone2.png" className="img-fluid" alt="" />
                </div>
                <h5 className="section-title"> About Us</h5>
                <h2 className="section-subheading"> What we can do for you </h2>
                <p>
                  We at Foresthaven can provide you with purebred Toy and
                  Miniature Schnauzers. We, not only breed Schnauzer pups of
                  remarkable quality both in looks and temperament but also
                  train them with love and care to make them a perfect pet for
                  you.
                </p>
                <Link to="/about" className="btn btn-secondary btn-brand">
                  {" "}
                  Explore Now <img src="/img/chevron-down.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main services-section bg-pink">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <div className="section-heading-content">
                <div className="paw-1 positioning d-none d-md-block d-lg-block d-xl-block">
                  <img
                    src="/img/services/Paw.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>

                <h5 className="section-title"> Our Process </h5>
                <h2 className="section-subheading">
                  {" "}
                  What makes us different?{" "}
                </h2>
                <p>
                  Our prominent effort for breeding Schnauzers comes from an
                  absolute love and adoration for them. Here’s what we do
                  differently:
                </p>
                <div className="paw-2 positioning d-none d-md-block d-lg-block d-xl-block">
                  <img
                    src="/img/services/Paw2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="card custom-card text-center">
                <div className="card-header">
                  <img src="img/services/care-love.png" alt="" />
                  <h3 className="card-title"> Born With Love And Care </h3>
                </div>
                <div className="card-body">
                  <p>
                    We raise breed and raise pups like our own with love and
                    affection. We have built them the biggest playground area
                    where they receive continuous and devoted attention.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card custom-card text-center">
                <div className="card-header">
                  <img src="img/services/quality-breed.png" alt="" />
                  <h3 className="card-title"> Top Quality Nutrition </h3>
                </div>
                <div className="card-body">
                  <p>
                    We only provide top-quality nutritious food to our pups. On
                    top of that, they are regularly checked by the vets,
                    vaccinated, and wormed as per the ideal guidelines.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card custom-card text-center">
                <div className="card-header">
                  <img src="img/services/Food.png" alt="" />
                  <h3 className="card-title"> Mental Development </h3>
                </div>
                <div className="card-body">
                  <p>
                    We continuously expose the pups to new people and situations
                    as they grow. We let them play inside, outside, take them
                    for car rides. Their brains develop best when they are
                    exposed to the right stimulation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card custom-card text-center">
                <div className="card-header">
                  <img src="img/services/hand.png" alt="" />
                  <h3 className="card-title"> Well Trained </h3>
                </div>
                <div className="card-body">
                  <p>
                    Untrained pets can wreak havoc on your house. That is why
                    all our Schnauzer pups are cater-trained, toilet-trained,
                    and trained to use pet doors to enter and exit the house.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card custom-card text-center">
                <div className="card-header">
                  <img src="img/services/heart.png" alt="" />
                  <h3 className="card-title"> Quality Breeding </h3>
                </div>
                <div className="card-body">
                  <p>
                    We only breed pups out of the purest bloodline of Schnauzer
                    dogs with the right conformation and temperament making
                    little pups of the purest quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card custom-card text-center">
                <div className="card-header">
                  <img src="img/services/Genetics.png" alt="" />
                  <h3 className="card-title"> Healthy Genetics </h3>
                </div>
                <div className="card-body">
                  <p>
                    We always make sure that the Schnauzer dogs have been
                    screened for and passed genetic health conditions so that
                    only healthy genes are passed through them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main testimonial-section custom-swiper-nav">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <div className="section-heading-content">
                <div className="paw-1 positioning d-none d-md-block d-lg-block d-xl-block">
                  <img
                    src="/img/testimonial/testimonial2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h5 className="section-title">
                  {" "}
                  STORY OF OUR HAPPIEST CLIENTS{" "}
                </h5>
                <h2 className="section-subheading">
                  Meet some of our clients who have treated pets like family{" "}
                </h2>

                <div className="paw-2 positioning d-none d-md-block d-lg-block d-xl-block">
                  <img
                    src="/img/testimonial/testimonial1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-5 d-none d-md-block d-lg-block d-xl-block">
              <div className="img-cont">
                <img src="/img/testi-dog.png" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-7">
              <Swiper
                slidesPerView={1}
                pagination={false}
                navigation={true}
                className="mySwiper"
              >
                {testimonial.map((item) => {
                  return (
                    <SwiperSlide key={item._id}>
                      <div className="testimonial-wrapper">
                        <div className="testimonial-header d-flex align-items-center">
                          <div className="img-circle">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/public/${item.thumbnail}`}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="info-content ml-3">
                            <h4> {item.customerName} </h4>
                            <small> Customer </small>
                          </div>
                        </div>
                        <div className="testimonial-body">
                          <p> {item.description}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="main gallery-section bg-pink">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <div className="section-heading-content">
                <div className="bone-1">
                  <img
                    src="/img/gallery/gallery2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="bone-2">
                  <img
                    src="/img/gallery/gallery1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h5 className="section-title">OUR GALLERY</h5>
                <h2 className="section-subheading">
                  {" "}
                  Some of our Precious Memories
                </h2>
                <p>
                  Take a look at these adorable pictures of these Schnauzers. We
                  raise them with the utmost love and care so that they can be
                  the perfect pet for your family
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="gallery-wrapper grid-1">
                <Lightbox
                  index={index}
                  open={index >= 0}
                  close={() => setIndex(-1)}
                  slides={gallery.map((item) => {
                    return {
                      src: `${process.env.REACT_APP_API_URL}/public/${item.image}`,
                    };
                  })}
                />
                <div className="gallery-1-item" onClick={() => setIndex(0)}>
                  {gallery[0]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[0]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
                <div className="gallery-1-item" onClick={() => setIndex(1)}>
                  {gallery[1]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[1]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
                <div className="gallery-1-item" onClick={() => setIndex(2)}>
                  {gallery[2]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[0]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
                <div className="gallery-1-item" onClick={() => setIndex(3)}>
                  {gallery[3]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[3]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
                <div className="gallery-1-item" onClick={() => setIndex(4)}>
                  {gallery[4]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[4]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
              </div>

              <div className="mt-3 mb-3"></div>

              <div className="gallery-wrapper grid-2">
              <div className="gallery-2-item" onClick={() => setIndex(5)}>
                  {gallery[5]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[5]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
              <div className="gallery-2-item" onClick={() => setIndex(6)}>
                  {gallery[6]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[6]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
              <div className="gallery-2-item" onClick={() => setIndex(7)}>
                  {gallery[7]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[7]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
              <div className="gallery-2-item" onClick={() => setIndex(8)}>
                  {gallery[8]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[8]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
              <div className="gallery-2-item" onClick={() => setIndex(9)}>
                  {gallery[9]?.image ?  <img
                    src={`${process.env.REACT_APP_API_URL}/public/${gallery[9]?.image}`}
                    className="img-fluid"
                    alt=""
                  /> : ""}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="section-content section-heading-content positioning">
                <div className="paw-1 d-none d-md-block d-lg-block d-xl-block">
                  <img src="/img/faq/faq.png" className="img-fluid" alt="" />
                </div>
                <h5 className="section-title"> FREQUENTLY ASKED QUESTIONS</h5>
                <h2 className="section-subheading">
                  Get to know More about us{" "}
                </h2>
                <p>
                  {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.Lorem Ipsum has been the industry's
                  standard dummy . */}
                </p>
              </div>

              <div className="faq-wrapper">
                <div className="accordion" id="accordionFaq">
                  {faq.map((item, index) => {
                    return (
                      <div key={index} className="card">
                        <div className="card-header">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${index}`}
                            >
                              {item.question}
                            </button>
                            <span className="icon-wrapper">
                              <FontAwesomeIcon icon={faPlus} />
                            </span>
                          </h2>
                        </div>

                        <div id={`collapse${index}`} className="collapse">
                          <div className="card-body">{item.answer}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-5 d-none d-md-block d-lg-block d-xl-block">
              <div className="section-image-wrapper">
                <img src="/img/faq-image.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
