import { useState } from "react";
import { http } from "../utils/httpreq";
import { Navigate, useParams } from "react-router-dom";
import CustomButton from "./Button";
import notify from "../utils/notify";

const PictureAdd = () => {
  const params = useParams();
  //   const [title, setTitle] = useState();
  //   const [thumbnail, setThumbnail] = useState();
  const [logging, setLogging] = useState(false);
  const [info, setInfo] = useState({
    title: "",
  });
  const [files, setFiles] = useState([]);
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" />;
  }

  const handleChange = (e) => {
    var { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();
    const formData = new FormData();

    for (let key in info) {
      formData.append(key, info[key]);
    }
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images", files[i]);
      }
    }
    try {
      const postRequiredData = await http(true, true).post(
        "/pictures/" + params.id,
        formData
      );
      setFiles([]);
      setInfo({ title: "" });
      setLogging(false);
      notify.showSuccess("Pictures added successfully.");
    } catch (err) {
      setLogging(false);
      notify.showError(err.response);
    }
  }
  return (
    <>
      <div className="admin-form main-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Add Pictures </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-row mb-4">
                  {/* <div className="col">
                    <label htmlFor="exampleName"> Title </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleName"
                      name="title"
                      onChange={handleChange}
                      aria-describedby="nameHelp"
                    />
                  </div> */}
                  <div className="col">
                    <label htmlFor="customFile">Pictures </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        multiple
                        onChange={(e) =>
                          setFiles([...files, ...e.target.files])
                        }
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
                {/* <button type="submit" className="btn btn-brand-warning">
                  Submit
                </button> */}
                <CustomButton isDisabled={logging} />
              </form>

              <div className="row">
                <div className="col-md-12">
                  <div className="masorny-grid">
                    {files.map((item, index) => {
                      return (
                        <figure key={index}>
                          <img
                            src={URL.createObjectURL(item)}
                            className="img-fluid"
                            alt={item.name}
                          />
                        </figure>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PictureAdd;
