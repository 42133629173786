import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { http } from "../utils/httpreq";

const Faq = () => {
  let [faq, setFaq] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getRequiredData = async () => {
      const faqData = await http(null, null)
        .get("/faq")
        .then((res) => {
          setFaq(res.data);
        })
        .catch((err) => {
        });
    };
    getRequiredData();
  }, []);

  return (
    <>
      <div className="faq-page main">
        <div className="container">
        <div className="row">
            <div className="col-md-12">
              <div className="section-heading-content text-center">
                <h5 className="section-title"> Faq's </h5>
                <h2 className="section-subheading">
                  {" "}
                  Get to know More about us{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-12">
              <div className="faq-wrapper">
                <div className="accordion" id="accordionFaq">
                  {faq.map((item, index) => {
                    return (
                      <div className="card" key={index}>
                        <div
                          className="card-header"
                          id={`heading${index.toString()}`}
                        >
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${index.toString()}`}
                              aria-expanded="true"
                              aria-controls="{`collapse${index.toString()}`}"
                            >
                              {item.question}
                            </button>
                            <span className="icon-wrapper">
                              <FontAwesomeIcon icon={faPlus} />
                            </span>
                          </h2>
                        </div>

                        <div
                          id={`collapse${index.toString()}`}
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionFaq"
                        >
                          <div className="card-body">{item.answer}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
