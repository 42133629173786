import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { http } from "../utils/httpreq";
import Testimonial from "./Testimonial";
import { Link } from "react-router-dom";
import notify from "../utils/notify";

const GalleryList = () => {
  let [gallery, setGallery] = useState([]);
  const [isDeleting, setIsDeleting] = useState({
    state: false,
    id: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getRequiredData = async () => {
      try {
        setIsLoading(true);
        const res = await http(null, null).get("/gallery");
        setGallery(res.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    getRequiredData();
  }, []);

  const handleDeleteGallery = async (id) => {
    try {
      setIsDeleting({ state: true, id: id });
      await http(true, false).delete("/gallery/" + id);
      const newGallery = gallery.filter((g) => g._id !== id);
      setGallery(newGallery);
      setIsDeleting({ state: false, id: id });
      notify.showSuccess("Gallery deleted successfully.");
    } catch (err) {
      setIsDeleting({ state: false, id: id });
      notify.showError(err.response);
    }
  };
  return (
    <>
      {/* <section className="subnav-wrapper bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ul className="list-unstyled d-flex mb-0 justify-content-between">
                <li className="active">
                  <Link to="/admin/testimonial/list" className="">
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link to="/admin/gallery/list" className="">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/admin/faq/list" className="">
                    FAQ's
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <section className="main testimonial-table">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h4 className="admin-title"> Gallery List </h4>
            </div>
            <div className="col-md-2 text-right">
              <Link to="/admin/gallery/add" className="btn btn-brand-warning">
                {" "}
                <img
                  src="/img/add.png"
                  style={{ width: "12px", marginRight: "15px" }}
                  alt=""
                />
                Add New{" "}
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <table className="table admin-table">
                <thead>
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col"> Album Title</th>

                    <th scope="col">CreatedAt</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {gallery.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td scope="row">{index + 1}</td>
                        <td className="username">
                          {" "}
                          <Link to={`/gallery/${item._id}`} className="mr-2">
                            {item.title}
                          </Link>
                        </td>

                        <td>{item.createdAt}</td>
                        <td>
                          {" "}
                          <Link to="/" className="mr-2">
                            <img src="/img/view.png" alt="" />
                          </Link>
                          <Link
                            to={`/admin/gallery/${item._id}`}
                            className="mr-2"
                          >
                            <img src="/img/edit.png" alt="" />
                          </Link>
                          {isDeleting &&
                          isDeleting.state &&
                          isDeleting.id === item._id ? (
                            "loading"
                          ) : (
                            <Link
                              to=""
                              onClick={() => handleDeleteGallery(item._id)}
                              className="mr-2"
                            >
                              <img src="/img/delete.png" alt="" />
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GalleryList;
