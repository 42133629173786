import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../utils/httpreq";
import CustomButton from "./Button";
import notify from "../utils/notify";
const Contact = () => {
  const [isLogging, setIsLogging] = useState(false);
  const [info, setInfo] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = function (e) {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
    try {
      setIsLogging(true);
      const response = await http(true, false).post("/contact", info);
      setInfo({ fullName: "", email: "", phone: "", message: "" });

      setIsLogging(false);
      notify.showSuccess(
        "We have received your inquiry. We will contact you soon. Thank you!"
      );
    } catch (err) {
      notify.showError(err.response);
      setIsLogging(false);
    }
  };
  return (
    <>
      <div className="main other-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <div className="section-heading-content">
                <h5 className="section-title"> ways to get touch with us </h5>
                <h2 className="section-subheading">
                  wanna get in <br /> touch?
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="contact-page-form row contact-form slick-form">
                <div className="form-left col-md-5">
                  <h3> Lets get in touch </h3>
                  <p> we are open for any suggestion or just to have a chat</p>

                  <ul className="list-unstyled">
                    <li className="d-flex align-items-start mb-3">
                      <div className="icon-circle">
                        <img src="/img/contact/call.png" alt="" />
                      </div>
                      <div className="info">
                        <h5> Phone </h5>
                        <p> 0498 196 670 </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-start mb-3">
                      <div className="icon-circle">
                        <img src="/img/contact/address.png" alt="" />
                      </div>
                      <div className="info">
                        <h5> Address </h5>
                        <p> Jervis Bay, NSW 2540, Australia </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-start mb-3">
                      <div className="icon-circle">
                        <img src="/img/contact/mail.png" alt="" />
                      </div>
                      <div className="info">
                        <h5> Email </h5>
                        <p> foresthavenschnauzers@gmail.com </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-start mb-3">
                      <div className="icon-circle">
                        <img src="/img/contact/clock.png" alt="" />
                      </div>
                      <div className="info">
                        <h5> Opening Time </h5>
                        <p> 9am - 6pm </p>
                      </div>
                    </li>
                  </ul>

                  <ul className="list-unstyled social-icons d-flex">
                    <li>
                      <Link to="" className="">
                        <img
                          src="/img/contact/facebook.png"
                          className=""
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="">
                        <img
                          src="/img/contact/twitter.png"
                          className=""
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="">
                        <img
                          src="/img/contact/instagram.png"
                          className=""
                          alt=""
                        />
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="form-right col-md-7">
                  <h3 className="mb-5"> Send us a Message </h3>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="fullname1">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullname1"
                        name="fullName"
                        onChange={handleChange}
                        value={info.fullName}
                        required
                        placeholder="Enter Fullname"
                        aria-describedby="fullname1Help"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="userEmail1">Email address</label>
                      <input
                        type="email"
                        onChange={handleChange}
                        value={info.email}
                        name="email"
                        required
                        className="form-control"
                        id="userEmail1"
                        placeholder="Enter Email"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="userNumber1">Phone Number</label>
                      <input
                        name="phone"
                        value={info.phone}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Enter your mobile number"
                        id="userNumber1"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="messageArea">Message</label>
                      <textarea
                        name="message"
                        value={info.message}
                        onChange={handleChange}
                        className="form-control"
                        id="messageArea"
                        rows="3"
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                    <CustomButton
                      isDisabled={isLogging}
                      enabledLable="send"
                      disabledLable="sending..."
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
