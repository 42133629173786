import { useState, useHistory } from "react";
import { http } from "../utils/httpreq";
import { useNavigate } from "react-router-dom";
import CustomButton from "./Button";
import notify from "../utils/notify";
const Login = (props) => {
  const [info, setInfo] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [isLogging, setIsLogging] = useState(false);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setInfo({ ...info, [name]: value }); 
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLogging(true);
      const response = await http(null, null).post("/auth/login", info);
      localStorage.setItem("loggedInUser", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      setIsLogging(false);
      notify.showSuccess(`Welcome ${response.data.user.username}`)
      navigate("/admin/home");
    } catch (err) {
      setIsLogging(false);
      notify.showError(err.response)
    
    }
  };
  return (
    <>
      <div className="login-wrapper main admin-form">
        <div className="login-card">
          <h3 className="admin-title"> Login </h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input
                onChange={handleChange}
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Password</label>
              <input
                onChange={handleChange}
                type="password"
                name="password"
                className="form-control"
                id="exampleInputPassword1"
              />
            </div>

            <div className="text-left">
              {/* <button type="submit" className="btn btn-brand-warning">
                Login
              </button> */}
              <CustomButton
                enabledLable="Login"
                disabledLable="Logingin..."
                isDisabled={isLogging}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
