import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { http } from "../utils/httpreq";
import CustomButton from "./Button";
import Back from "./Common/Back";
const defaultData = {
  question: "",
  answer: "",
};
const FaqEdit = () => {
  //1. getting value from the field input
  const params = useParams();
  const [info, setInfo] = useState(defaultData);
  const [logging, setLogging] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const runAsync = async function () {
      try {
        const response = await http(true, false).get(`/faq/${params.id}`);
        const data = response.data;
        setInfo({
          ...info,
          question: data.question || "",
          answer: data.answer || "",
        });
      } catch (err) {
      }
    };
    runAsync();
  }, []);

  if (!token) {
    return <Navigate to="/" />;
  }

  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();

    try {
      const postRequiredData = await http(true, null).put(
        "/faq/" + params.id,
        info
      );

      setLogging(false);
    } catch (err) {
      setLogging(false);
    }
  }

  function handleChange(e) {
    let { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  }
  return (
    <>
      <div className="admin-form main-md">
        <div className="container">
          <Back page="faq" />
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Add FAQ's </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit}>
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="questionField"> Question</label>
                    <input
                      type="text"
                      name="question"
                      value={info.question}
                      className="form-control"
                      id="questionField"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="answerField"> Answer </label>
                    <textarea
                      className="form-control"
                      id="answerField"
                      name="answer"
                      placeholder="Add Answer"
                      onChange={handleChange}
                      value={info.answer}
                      required
                    >
                      {/* {info.answer} */}
                    </textarea>
                  </div>
                </div>

                <CustomButton isDisabled={logging} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqEdit;
