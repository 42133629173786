import { useEffect, useState } from "react";
import { http } from "../utils/httpreq";
import { Link, Navigate, useParams } from "react-router-dom";
import CustomButton from "./Button";
import notify from "../utils/notify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTimes } from "@fortawesome/free-solid-svg-icons";
import Back from "./Common/Back";

const GalleryEdit = () => {
  //   const [title, setTitle] = useState();
  //   const [thumbnail, setThumbnail] = useState();
  const [info, setInfo] = useState({
    title: "",
  });
  const params = useParams();
  const [file, setFile] = useState("");
  const [oldImages, setOldImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [logging, setLogging] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const runAsync = async function () {
      try {
        const response = await http(true, false).get(
          "/gallery/picture/" + params.id
        );
        if (response.data) {
          setOldImages(response.data.picture);
          setInfo({
            ...info,
            title: response.data.gallery?.title || "",
          });
          setFile(response.data.gallery.thumbnail);
        }
      } catch (err) {
        notify.showError(err.response)
      }
    };

    runAsync();
  }, []);

  if (!token) {
    return <Navigate to="/" />;
  }

  const deleteNewPicture = (item) => {
    let newpictures = newImages.filter((img) => img.name !== item.name);
    setNewImages(newpictures);
  };
  const deleteOldPicture = async (id) => {
    try {
      await http(true, false).delete("/picture/" + id);
      const oldPictures = oldImages.filter((img) => img._id !== id);
      setOldImages(oldPictures);
    } catch (err) {
      notify.showError(err.response)
    }
  };

  const handleChange = (e) => {
    var { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  async function handleSubmit(e) {
    setLogging(true);
    e.preventDefault();
    const formData = new FormData();

    for (let key in info) {
      formData.append(key, info[key]);
    }

    if (file && typeof file === "object") {
      formData.append("thumbnail", file);
    }

    if (newImages.length) {
      for (let i = 0; i < newImages.length; i++) {
        formData.append("picture", newImages[i]);
      }
    }
    try {
      const response = await http(true, true).put(
        "/gallery/" + params.id,
        formData
      );
      if (response.data && response.data.length) {
        setOldImages(response.data);
      }
      setNewImages([]);
      setLogging(false);
      notify.showSuccess("Gallery edited successfully.");
    } catch (err) {
      setLogging(false);
      notify.showError(err.response);
    }
  }
  return (
    <> 
      <div className="admin-form main-md">
        <div className="container">
          <Back page="gallery" />
          <div className="row">
            <div className="col-md-12">
              <h4 className="admin-title"> Add Gallery </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="exampleName"> Title </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleName"
                      name="title"
                      value={info.title}
                      onChange={handleChange}
                      aria-describedby="nameHelp"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="customFile">Thumbnail </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    {file ? (
                      typeof file == "object" ? (
                        <figure className="img-circle">
                          <img
                            src={URL.createObjectURL(file)}
                            className="img-fluid"
                            alt={file.name}
                          />
                        </figure>
                      ) : (
                        <figure className="img-circle">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/public/${file}`}
                            className="img-fluid"
                            alt={file.name}
                          />
                        </figure>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* pictures */}
                <div className="form-row mb-4">
                  <div className="col">
                    <label htmlFor="customFile">Add New Images </label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        multiple
                        onChange={(e) =>
                          setNewImages([...newImages, ...e.target.files])
                        }
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
                <CustomButton isDisabled={logging} />
              </form>
            </div>
          </div>
          <hr className="mt-4"/>
          {/* picture list */}
          <div className="row mt-3">
            <div className="col-md-12">
              <h4>New Images</h4>

            </div>
            <div className="col-md-12">
              <div className="masorny-grid">
                {newImages.map((item, index) => {
                  return (
                    <figure key={index}>
                      <img
                        src={URL.createObjectURL(item)}
                        className="img-fluid"
                        alt="A windmill"
                      />
                      
                      <button
                        onClick={() => {
                          deleteNewPicture(item);
                        }}
                      >
                         <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </figure>
                  );
                })}
              </div>
            </div>
          </div>
          <hr />
          {/* picture list */}
          <div className="row mt-3">
            <div className="col-md-12">
              <h4>Old Images</h4>

            </div>
            <div className="col-md-12">
              <div className="masorny-grid">
                {oldImages.map((item, index) => {
                  return ( 
                    <figure key={index}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/public/${item.image}`}
                        className="img-fluid"
                        alt={item.image}
                      />
                      <button className="btn close-btn"
                        onClick={() => {
                          deleteOldPicture(item._id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </figure>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryEdit;
