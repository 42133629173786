import { Link, Navigate, useNavigate } from "react-router-dom";

const Navigation = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };
  let logOutBtn = token ? (
    <>
      <li className="nav-item">
        <Link className="nav-link" to="/admin/home">
          Admin
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" onClick={handleLogOut} to="">
          Logout
        </Link>
      </li>
    </>
  ) : (
    ""
  );

  return (
    <>
      <header className="custom-nav with-banner-bg">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to="/">
              <img src="/img/foresthaven-logo.png" alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    {" "}
                    About{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/gallery">
                    {" "}
                    Gallery{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    {" "}
                    Contact{" "}
                  </Link>
                </li>
                {logOutBtn}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navigation;
