import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { http } from "../utils/httpreq";
import notify from "../utils/notify";

import Lightbox from "yet-another-react-lightbox";

const GalleryDetails = () => {
  const [index, setIndex] = React.useState(-1);

  const params = useParams();
  const [picture, setPictures] = useState([]);
  useEffect(() => {
    const runAsync = async function () {
      try {
        const response = await http(null, null).get(
          "/gallery/pictures/" + params.id
        );
        setPictures(response.data);

       
      } catch (err) {
        notify.showError(err.response);
      }
    };
    runAsync();
  }, []);
  const token = localStorage.getItem("token");
  return (
    <>
      <div className="main other-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <h5 className="section-title"> Our Gallery </h5>
              <h2 className="section-subheading">
                {" "}
                Some of our precious memories
              </h2>
            </div>
          </div>
          {token ? (
            <div className="row mb-3">
              <div className="col-md-10">
                
              </div>
              <div className="col-md-2 text-right">
                <Link
                  to={`/admin/pictures/add/${params.id}`}
                  className="btn btn-brand-warning"
                >
                  {" "}
                  <img
                    src="/img/add.png"
                    style={{ width: "12px", marginRight: "15px" }}
                    alt=""
                  />
                  Add New{" "}
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div>
             
            </div>
            <div className="col-md-12">
              <div className="masorny-grid">
              
                <Lightbox
                index={index}
                open={index >= 0}
                close={() => setIndex(-1)}
                  slides={picture.map((item) => {
                    return {
                      src:`${process.env.REACT_APP_API_URL}/public/${item.image}`,
                    };
                  })}
                /> 
             
                 {picture.map((item, index) => {
                    return (
                      <figure onClick={() => setIndex(index)} key={index}>
                        <img
                          
                          src={`${process.env.REACT_APP_API_URL}/public/${item.image}`}
                          className="img-fluid"
                          alt={item.image}
                        />
                      </figure>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryDetails;
