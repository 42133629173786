import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "./css/animate.css";
import "yet-another-react-lightbox/styles.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import $ from "jquery";
import "popper.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
